<template>
    <!-- Segmentations -->
    <div class="card my-3" style="margin: 20px">
        <DataTable 
            :value="segmentations" 
            :loading="loading" 
            responsiveLayout="scroll" 
            showGridlines 
            :rowHover="true"
            removableSort 
            datakey="id" 
            selectionMode="single"
        >
            <!-- Name -->
            <Column 
                :header="'Name'" 
                :sortable="true"
            >
                <template #body="slotProps">
                    <div @click="onRowSelect(slotProps)">
                        {{ slotProps.data.name }}
                    </div>
                </template>
            </Column>
            <!-- SegmentationItems -->
                <!-- Region -->
            <Column
                :header="'SegmentationItems'"
            >
                <template #body="slotProps">
                    <div 
                        v-if="slotProps.data.isRegional" 
                        @click="onRowSelect(slotProps)"
                    >
                        {{ 
                            slotProps.data.segmentationItems.map(
                                segmentationItem => 
                                segmentationItem.region.name
                            ).join('--')
                        }}
                    </div>
                    <div v-else @click="onRowSelect(slotProps)">
                        {{ 
                            slotProps.data.segmentationItems.map(
                                segmentationItem => 
                                segmentationItem.name
                            ).join('--')
                        }}
                    </div>
                </template>
            </Column>
            <!-- SegmentationItems -->
                <!-- Other Segmentations -->
            <Column
                :exportable="false"
                style="width:20rem"
            >
                <template #body="slotProps">
                    <div
                    >
                        <div 
                            v-if="!slotProps.data.isRegional" 
                            style="display: flex; justify-content: center;"
                        >
                            <div style="padding-right: 3rem;" >
                                <Button
                                    :disabled="slotProps.data.order == 1"
                                    icon="pi pi-arrow-up"
                                    class="p-button-rounded p-button-info mx-2"
                                    @click="changeOrder(slotProps.data, true)"
                                />
                                <Button
                                    :disabled="slotProps.data.order == segmentations.length - 1"
                                    icon="pi pi-arrow-down"
                                    class="p-button-rounded p-button-danger"
                                    @click="changeOrder(slotProps.data, false)"
                                />
                            </div>
                            <div>
                                <!-- <Button 
                                    icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success mx-2"
                                    @click="onRowSelect(slotProps)"
                                /> -->

                                <Button
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger"
                                    @click="openDeleteSegmentationDialog(slotProps.data)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add Segmentation -->
    <div style="width: 25%; margin: 20px;">
        <Button
            type="button"
            icon="pi pi-plus"
            label="Add Segmentation"
            class="p-button-outlined"
            @click="openCreateSegmentationDialog()"
        />
    </div>

    <!-- Create Segmentation-->
    <Dialog 
        v-model:visible="createSegmentationDialog" 
        :style="{ width: '90%' }" 
        header="Create Segmentation"
        :modal="true" 
        class="p-fluid" 
        :maximizable="true"
    >
        <div class="field">
            <label for="name">Name</label>
            <InputText 
                id="name" 
                v-model.trim="newSegmentationForm.name" 
                @blur="v$.newSegmentationForm.name.$touch"
                required="true" 
                autofocus 
                :class="{ 'p-invalid': !newSegmentationForm.name }" 
            />
            <small class="p-error" v-if="!newSegmentationForm.name">Name is required.</small>
        </div>
        <template #footer>
            <Button 
                label="Cancel" 
                icon="pi pi-times" 
                class="p-button-text" 
                @click="closeDialog()" 
            />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                class="p-button-text" 
                :loading="createSegmentationIsLoading"
                :disabled="
                    this.v$.newSegmentationForm.$invalid || createSegmentationIsLoading
                " @click="createSegmentation()" />
        </template>

    </Dialog>

    <!-- Edit Segmentation  -->
    <Dialog
      v-model:visible="editSegmentationDialog"
      header="Edit Segmentation"
      :modal="true"
      class="p-fluid dialogModal"
      @hide="closeDialog()"
    >
        <Divider align="left">
            <div class="inline-flex align-items-center">
                <span class="p-tag">INFORMATION</span>
            </div>
        </Divider>
        <div class="field">
            <label for="name">Name</label>
            <InputText
                id="name"
                v-model.trim="editSegmentationForm.name"
                @blur="v$.editSegmentationForm.name.$touch"
                required="true"
                autofocus
                :class="{ 'p-invalid': !editSegmentationForm.name }"
            />
            <small class="p-error" v-if="!editSegmentationForm.name"
                >Name is required.</small
            >
        </div>
        <Divider align="left">
            <div class="inline-flex align-items-center">
                <span class="p-tag">ITEMS</span>
            </div>
        </Divider>
        <div class="field">
            <div class="confirmation-content d-flex flex-column mt-3">
                <div class="d-flex">
                    <InputText
                        placeholder="New segmentation item"
                        v-model="newItem"
                        type="text"
                    />

                    <Button
                        class="p-button-raised p-button-rounded p-button-primary ms-3"
                        icon="pi pi-plus"
                        @click="addItemToSegmentationItems()"
                    />
                </div>
                <template v-if="!selectedRowSegmentation.isRegional">
                    <SegmentationItemsList
                        :segmentationItems="selectedRowSegmentation.segmentationItems"
                        :isRegional="selectedRowSegmentation.isRegional"
                        @changeSegmentationItemOrderEmits="changeSegmentationItemOrder"
                    />
                </template>
            </div>
        </div>
        <template #footer>
            <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog()"
            />
            <Button
                label="Save"
                icon="pi pi-check"
                class="p-button-primary"
                :loading="editSegmentationIsLoading"
                :disabled="
                this.v$.editSegmentationForm.$invalid || editSegmentationIsLoading
                "
                @click="editSegmentation()"
            />
        </template>
    </Dialog>

    <!-- Edit Region Segmentation -->
    <Dialog
        v-model:visible="editRegionSegmentationDialog"
        header="Edit Segmentation By Region" 
        :modal="true"
        class="p-fluid dialogModal"
        @hide="closeDialog()"
    >
        <Divider align="left">
            <div class="inline-flex align-items-center">
                <span class="p-tag">INFORMATION</span>
            </div>
        </Divider>

        <!-- Name -->
        <div class="field">
            <label for="name">Name</label>
            <InputText 
                id="name"
                v-model.trim= "editSegmentationForm.name"
                @blur="v$.editSegmentationForm.name.$touch"
                required="true"
                autofocus
                :class="{ 'p-invalid': !editSegmentationForm.name }" 
            />
            <small class="p-error" v-if="!editSegmentationForm.name">Name is required.</small>
        </div>
        
        <!-- Region -->
        <Divider align="left">
            <div class="inline-flex align-items-center">
                <span class="p-tag">Region</span>
            </div>
        </Divider>

        <div class="field">
            <div class="confirmation-content d-flex flex-column my-3">
                <label for="name">Add Region To Segmentation</label>

                <div class="d-flex">
                    <Dropdown
                        :options="regions"
                        v-model="newItem"
                        optionLabel="name"
                        optionValue="regionId"
                        :filter="true"
                        placeholder="Select a Region"
                        :showClear="true"
                        class="w-25"
                    >
                    </Dropdown>

                    <Button 
                        class="p-button-raised p-button-rounded p-button-primary ms-3" 
                        icon="pi pi-plus"
                        @click="addItemToRegionSegmentationItems()" 
                    />

                    <div v-if="repeatedCountry.length > 0"
                        style="
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 2rem;
                        ">
                            <i class="p-error pi pi-exclamation-circle" style="font-size: 2rem; margin-right: 0.5rem;"></i>
                            <small class="p-error"  >Some countries are repeated</small>
                    </div>
                </div>


                <div
                    v-for="segmentationItem , index in selectedRowSegmentation.segmentationItems"
                    :key="segmentationItem.segmentationItemId"
                    style="
                        display: flex;
                        flex-direction: column;
                    "
                >
                    <div class="my-2 d-flex justify-content-between align-items-center">
                        <div style="display: flex; align-items: center; width: 50%; ">
                            <h5> {{segmentationItem.region.name}}</h5> 
                        </div>
                    </div>
                    <div class="my-2 d-flex justify-content-between align-items-center">
                        <!-- <Divider> -->
                        <div style="display: flex; align-items: center; width: 70%; gap: 1rem; ">
                            <div style="display: flex; align-items: center; gap: 1rem; width: 30%;">
                                <label>Name</label>
                                <InputText 
                                    v-model.trim= "segmentationItem.name"
                                />
                            </div>
                            <div style="display: flex; align-items: center; gap: 1rem; width: 70%;">
                                <label>Description</label>
                                <InputText 
                                    v-model.trim= "segmentationItem.description"
                                />
                            </div>
                        </div>
                            
                        <!-- </Divider> -->

                        <div style="display: flex; align-items: center; " >
                            <label style="display: flex; align-items: center; justify-content: center; width: 10rem;"> Order number {{ segmentationItem.order }} </label>

                            <!-- Button SegmItems Order -->
                            <Button
                                :disabled="segmentationItem.order == 0"
                                icon="pi pi-arrow-up"
                                class="p-button-rounded p-button-info mx-2"
                                @click="changeSegmentationItemOrder(segmentationItem, true)"
                            />
                            <!-- :disabled="slotProps.data.order == 1" -->
                            <!-- @click="changeOrder(slotProps.data, true)" -->
                            <Button
                                :disabled="segmentationItem.order == selectedRowSegmentation.segmentationItems.length -1"
                                icon="pi pi-arrow-down"
                                class="p-button-rounded p-button-danger"
                                @click="changeSegmentationItemOrder(segmentationItem, false)"
                            />

                            <!-- :disabled="slotProps.data.order == segmentations.length - 1" -->
                            <!-- @click="changeOrder(slotProps.data, false)" -->

                            <Button 
                                label="" 
                                class="p-button-raised p-button-rounded p-button-danger ms-3" 
                                icon="pi pi-trash"
                                @click="deleteItemFromRegionSegmentationItems(segmentationItem.segmentationItemId)" 
                            />
                        </div>
                    </div>  

                    <Chips 
                        :repeatedCountry="repeatedCountry"
                        :selectedRowSegmentation=selectedRowSegmentation.segmentationItems
                        :segmentationItem="segmentationItem" 
                        :index="index"
                        :regionCountries="regionCountries" 
                        @isRepeatCountry="isRepeatCountry" 
                    />
                </div>
            </div>
        </div>

        <template #footer>
            <Button 
                label="Cancel" 
                icon="pi pi-times" 
                class="p-button-text"
                @click="closeDialog()" 
            />
            <Button 
                label="Save" 
                icon="pi pi-check" 
                class="p-button-primary" 
                :loading="editSegmentationIsLoading" 
                :disabled="this.v$.editSegmentationForm.$invalid || editSegmentationIsLoading" 
                @click="editRegionSegmentation()" 
            />
            <!-- @click="editRegionSegmentation()"  -->
        </template>
    </Dialog>

    <!-- Delete Segmentation -->
    <Dialog
        v-model:visible="deleteSegmentationDialog"
        :style="{ width: '450px' }"
        header="Delete Segmentation"
        :modal="true"
    >
        <div class="confirmation-content d-flex flex-row">
            <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
            <span
                >Are you sure you want to delete
                <span class="fw-bold">{{ selectedRowSegmentation.name }}</span>
            </span>
        </div>
        
        <template #footer>
            <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog()"
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                :loading="deleteSegmentationIsLoading"
                :disabled="deleteSegmentationIsLoading"
                @click="deleteSegmentation()"
            />
        </template>
    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import SegmentationItemsList from './SegmentationItemsList.vue';
import Chips from './Chips.vue';
import { useToast } from 'vue-toastification';

// request
import { editSegmentation, editSegmentationItemOrder } from '../../segmentation/services/segmentation.service';
const toast = useToast()

export default {
    name: 'SegmentationTestList',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        Dialog,
        // // ToggleButton,
        SegmentationItemsList,
        Divider,
        Dropdown,
        Chips,
    },
    props: ['topicId'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            createSegmentationIsLoading: false,
            editSegmentationIsLoading: false,
            deleteSegmentationIsLoading: false,

            createSegmentationDialog: false,
            editSegmentationDialog: false,
            editRegionSegmentationDialog: false,
            deleteSegmentationDialog: false,
            selectedRowSegmentation: null,
            newSegmentationForm: {
                name: null,
                isRegional: false,
            },
            editSegmentationForm: {
                name: null,
                isRegional: false,
            },
            newItem: null,
            repeatedCountry: [] // for repeatedCountry
        }
    },
    validations() {
        return {
            newSegmentationForm: {
                name: { required }
            },
            editSegmentationForm: {
                name: { required }
            },
        };
    },
    computed: {
        segmentations() {
            const x = this.$store.getters.obtainSegmentations;
            return x.sort((a, b) => {
                return a.order - b.order
            })
        },
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        regions() {
            return this.$store.getters.obtainRegions;
        },
        regionCountries(){
            return this.$store.getters.obtainCountries;
        }
    },
    async mounted() {
        this.repeatedCountry= []
        await this.getSegmentations();
        await this.getRegions();
        await this.getCountries();
    },
    methods: {
        async getSegmentations() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSegmentations', this.topicId);
                // toast.success('Segmentations obtained successfully.');
                // console.log('getSegmentation');
                this.loading = false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        },
        async getRegions() {
            try {
                this.loading = true;
                if (this.regions.length < 1) {
                    await this.$store.dispatch('obtainRegions', { regionTypeId: null, withChildren: false });
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getCountries(){
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        // create segmentation functions
        openCreateSegmentationDialog(){
            this.createSegmentationDialog = true
        },
        async createSegmentation() {
            try {
                if (!this.v$.newSegmentationForm.$invalid) {
                this.createSegmentationIsLoading = true;
                const newSegmentation = {
                    name: this.newSegmentationForm.name,
                    topicId: this.topicId,
                    isRegional: this.newSegmentationForm.isRegional,
                };
                await this.$store.dispatch('addNewSegmentation', newSegmentation);
                this.createSegmentationIsLoading = false;
                toast.success('Successfully created.');
                this.closeDialog();
                } else {
                toast.error('Wrong segmentation data.');
                }
            } catch (error) {
                this.createSegmentationIsLoading = false;
                toast.error(error.message);
            }
        },
        // edit segmentations and edit region Segmentations 
        async onRowSelect(event) {
            const segmentation = event.data;
            segmentation.segmentationItems.forEach((segmentationItem) => {
                segmentationItem.regionId = parseInt(segmentationItem.regionId);
            });
            // console.log('select event ', segmentation);
            // this.selectedRowSegmentation = segmentation;
            // this.openEditSegmentationDialog(segmentation);
            if(!segmentation.isRegional){
                this.openEditSegmentationDialog(segmentation);
            }
            else {
                // console.log('regional segmentations');
                this.openEditRegionSegmentationDialog(segmentation);
            }
        },
        // for region 
        openEditRegionSegmentationDialog(segmentation){
            this.editRegionSegmentationDialog = true;
            segmentation.segmentationItems.sort((a,b) => a.order - b.order)
            let sortedSegmItems = segmentation
            // console.log('open2',sortedSegmItems);
            // this.selectedRowSegmentation = segmentation;
            this.selectedRowSegmentation = sortedSegmItems;
            this.editSegmentationForm = Object.create(segmentation);
        },

        async addItemToRegionSegmentationItems(){
            try {
                if (this.newItem && this.newItem != '') {
                const newSegmentationItem = {
                    name: null,
                    regionId: null,
                    segmentationId: this.selectedRowSegmentation.segmentationId,
                };
                if (this.selectedRowSegmentation.isRegional) {
                    newSegmentationItem.regionId = this.newItem;
                }

                const regionName = this.regions.find(region => region.regionId === newSegmentationItem.regionId) // get region name 
                const a = this.selectedRowSegmentation.segmentationItems.some(item => item.region.name === regionName.name); // validate if countryName exist in segItem

                if (!a) { //if not exist dispatch 
                    await this.$store.dispatch('addNewSegmentationItem', newSegmentationItem);
                    toast.success('Successfully created.');
                }
                this.newItem = null;
                }
            } catch (error) {
                toast.error(error.message);
            }
        },
        async deleteItemFromRegionSegmentationItems(segmentationItemId){
            try {
                const segmentationId = this.selectedRowSegmentation.segmentationId;
                await this.$store.dispatch('removeSegmentationItem', {
                segmentationItemId,
                segmentationId,
                });
                toast.success('Successfully removed.');
            } catch (error) {
                toast.error(error.message);
            }
        },
        isRepeatCountry(duplicated) {
          this.repeatedCountry = duplicated
        },
        async editRegionSegmentation(){
            try {
                if (!this.v$.editSegmentationForm.$invalid) {
                this.editSegmentationIsLoading = true;
                // console.log('segmentationItems' , this.selectedRowSegmentation.segmentationItems);
                let segmentationItemsForm = [] ; 
                this.selectedRowSegmentation.segmentationItems.map(s => segmentationItemsForm.push({ 
                    name: s.name,
                    segmentationitemId: s.segmentationItemId,
                    description: s.description,
                }))
                const segmentation = {
                    ...this.selectedRowSegmentation,
                    name: this.editSegmentationForm.name,
                    isRegional: this.editSegmentationForm.isRegional,
                    segmentationItems: segmentationItemsForm
                };
                await editSegmentation(segmentation.segmentationId , segmentation)
                this.editSegmentationIsLoading = false;
                toast.success('Successfully updated.');
                this.closeDialog();
                } else {
                toast.error('Wrong segmentation data.');
                }
            } catch (error) {
                this.editSegmentationIsLoading = false;
                toast.error(error.message);
            }
        },
        // for Not Region 
        openEditSegmentationDialog(segmentation){
            this.editSegmentationDialog= true 
            segmentation.segmentationItems.sort((a,b)=> a.order - b.order)
            let sortedSegmItems = segmentation
            this.selectedRowSegmentation = sortedSegmItems;
            this.editSegmentationForm= Object.create(segmentation);
        },
        async addItemToSegmentationItems(){
            try {
                if (this.newItem && this.newItem != '') {
                const newSegmentationItem = {
                    name: null,
                    regionId: null,
                    segmentationId: this.selectedRowSegmentation.segmentationId,
                };
                if (this.selectedRowSegmentation.isRegional) {
                    newSegmentationItem.regionId = this.newItem;
                } 
                else {
                    newSegmentationItem.name = this.newItem;
                }
                await this.$store.dispatch(
                    'addNewSegmentationItem',
                    newSegmentationItem
                );
                this.newItem = null;
                toast.success('Successfully created.');
                }
            } catch (error) {
                toast.error(error.message);
            }
        },
        async editSegmentation(){
            try {
                if (!this.v$.editSegmentationForm.$invalid) {
                this.editSegmentationIsLoading = true;
                // console.log('segmentationItems' , this.selectedRowSegmentation.segmentationItems);
                let segmentationItemsForm = [] ; 
                this.selectedRowSegmentation.segmentationItems.map(s => segmentationItemsForm.push({ 
                    name: s.name,
                    segmentationitemId: s.segmentationItemId,
                    description: s.description,
                }))
                const segmentation = {
                    ...this.selectedRowSegmentation,
                    name: this.editSegmentationForm.name,
                    isRegional: this.editSegmentationForm.isRegional,
                    segmentationItems: segmentationItemsForm
                };
                await this.$store.dispatch('modifySegmentation', segmentation);
                this.editSegmentationIsLoading = false;
                toast.success('Successfully updated.');
                this.closeDialog();
                } else {
                toast.error('Wrong segmentation data.');
                }
            } catch (error) {
                this.editSegmentationIsLoading = false;
                toast.error(error.message);
            }
        },
        // delete Segmentation
        openDeleteSegmentationDialog(segmentation) {
            this.deleteSegmentationDialog = true;
            this.selectedRowSegmentation = segmentation;
        },
        async deleteSegmentation(){
            try {
                this.deleteSegmentationIsLoading = true;
                await this.$store.dispatch(
                'removeSegmentation',
                this.selectedRowSegmentation.segmentationId
                );
                this.deleteSegmentationIsLoading = false;
                toast.success('Successfully removed.');
                this.closeDialog();
            } catch (error) {
                this.deleteSegmentationIsLoading = false;
                toast.error(error.message);
            }
        },

        async changeOrder(segmentation, goUp) {
            try {
                this.loading = true;
                await this.$store.dispatch('modifySegmentationOrder', {
                segmentationId: segmentation.segmentationId,
                goUp,
                });
                this.loading = false;
                this.getSegmentations();
            } catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        },

        async changeSegmentationItemOrder(segmentationItem, goUp){
            try {
                this.loading = true; 
                await editSegmentationItemOrder(segmentationItem.segmentationItemId, goUp);
                this.loading = false;
                await this.getSegmentations();

                let newSegmentationItem = this.segmentations.filter((segm)=>{
                    return segm.segmentationId === segmentationItem.segmentationId
                })
                let sortedNewSegmentationItem = newSegmentationItem[0];
                sortedNewSegmentationItem.segmentationItems.sort((a,b) => a.order - b.order)
                let sortedSegmItems = sortedNewSegmentationItem
                // console.log('sortedSegmItems',sortedSegmItems);
                this.selectedRowSegmentation = sortedSegmItems;
            } catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        },

        // closeDialog 
        closeDialog() {
            this.deleteSegmentationDialog = false;
            this.editSegmentationDialog = false;
            this.editRegionSegmentationDialog = false;
            this.createSegmentationDialog = false;
            this.newSegmentationForm.name = null
        },
        
    },
    watch: {
        async topicId(newVal) {
            if (newVal) {
                await this.getSegmentations();
            }
        },

        // segmentations(newVal) {
        //     if (newVal) {
        //         console.log('newValue',newVal);
                
                
        //     }
        // },
    },
};
</script>

<style>
.p-dropdown-chip-country {
  margin: 0;
  padding: 0;
  height: 1.5rem;
}

.p-dropdown-chip-country span {
  margin: 0;
  padding: 0;
}
</style>